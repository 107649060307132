import React, { useContext, useState } from "react";
import { Link, useHistory, Redirect } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
  Alert,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import app from "../../../fire_base";
import { AuthContext } from "../../../customComponents/Auth";
import "firebase/auth";
import * as firebase from "firebase/app";
import { frontendRoutes } from "../../../utils/constants";
import { useInput, useSubmit } from "../../../hooks/form";
import { handleValidation, validations } from "../../../utils/validations";
import useAlert from "../../../hooks/useAlert";
import "./login.css";
import RequestModal from "./RequestModal";
import { AppNavbarBrand } from "@coreui/react";
import logoMain from "../../../assets/img/brand/logo.svg";
export default function Login(props) {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const email = useInput("email", "", handleValidation, validations.EMAIL);
  const password = useInput(
    "password",
    "",
    handleValidation,
    validations.EMPTY
  );

  const appAlert = useAlert(false);
  function handleAlertVisibility() {
    appAlert.changeVisibility(false);
  }
  const submit = useSubmit([email, password], handleSuccess);

  function handleSuccess(formData) {
    console.log("Data Length==> ", formData);
    login(formData);
  }

  const login = async (formData) => {
    // const { email, password } = event.target.elements
    console.log("email: %s, password: %s", formData.email, formData.password);
    try {
      await app
        .auth()
        .signInWithEmailAndPassword(formData.email, formData.password);
      history.push("/");
    } catch (error) {
      // alert(error)
      console.log("error from firebase: ", error);
      appAlert.changeVisibility(true);
      appAlert.handleMessage(error.message, true);
      setTimeout(() => {
        appAlert.changeVisibility(false);
      }, 5000);
    }
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  function handleGoogleAuth(e) {
    e.preventDefault();
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const token = result.credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // ...
        console.log("token: %s, user: %s", token, user);
        history.push("/");
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // const credential = error.credential;
        // ...
        console.log("error: %s, errorMessage: %s", error, errorMessage);
      });
  }

  // function handleRequestAlert(e) {
  //   e.preventDefault();
  //   setModal(true);
  // }

  return (
    <div className="app d-flex align-items-center login-card">
      <header>
        <div className="container">
          <div className=" d-flex flex-row align-items-center justify-content-between">
            <AppNavbarBrand
              //className="navBarBrandImage"
              full={{ src: logoMain, width: 160, alt: "CoreUI Logo" }}
            />
            <Nav
              className="d-md-down-none ml-5 flex-row align-items-center"
              navbar
            >
              <NavItem className="px-3">
                <NavLink to="/" className="nav-link">
                  Log In
                </NavLink>
              </NavItem>

              <NavItem className="px-3">
                <NavLink to="/" className="nav-link">
                  Sign Up
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </header>
      <Container>
        <RequestModal isOpen={modal} toggle={toggle} />
        <Row className="justify-content-center">
          <Col md="9">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <Alert
                    color={appAlert.isErrMsg ? "danger" : "success"}
                    isOpen={appAlert.isVisible}
                    toggle={handleAlertVisibility}
                  >
                    {appAlert.message}
                  </Alert>
                  <Form noValidate {...submit.props}>
                    <h1>Login</h1>

                    <InputGroup className="mb-3">
                      <Input
                        type="text"
                        placeholder="Email"
                        autoComplete="email"
                        {...email.props}
                      />
                      <FormFeedback>{email.helperText}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <Input
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                        {...password.props}
                      />
                      <FormFeedback>{password.helperText}</FormFeedback>
                    </InputGroup>
                    <Row>
                      <Col xs="12" className="text-center">
                        <Button color="primary" className="px-4">
                          Login
                        </Button>
                      </Col>
                      <Col xs="12" className="text-center forgot-password">
                        <Link to={frontendRoutes.forgotPassword.path}>
                          <Button color="link" className="px-0">
                            Forgot password?
                          </Button>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                  <div className="divider"></div>
                  {/* <Button onClick={handleGoogleAuth}>Google</Button> */}
                  <div className="g-button d-flex flex-row justify-content-center">
                    <p></p>
                    <Button
                      onClick={handleGoogleAuth}
                      color="white"
                      className="btn-google-plus btn-brand mr-1 mb-1"
                    >
                      <i className="fa fa-google"></i>
                      <span>Sign in with Google</span>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { adsAction } from '../actions';

export default function adsReducer(state = {}, action){
    switch(action.type){
        case adsAction.setAdData:
            return {...state, adData: action.payload};        
        case adsAction.setAdId:
            return {...state, adId: action.payload};
        default:
            return state
    }
}
import React, { useState, useEffect } from "react";
import app from "../fire_base";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [allAdvertisers, setAllAdvertisers] = useState([]);

  // function getAdvertisers() {
  //     apiPost(
  //         backendRoutes.GET_ADVERTISER,
  //         {},
  //         {
  //             "Content-Type": "application/json",
  //         },
  //         successRes => {
  //             let apiData = successRes.data.data;
  //             console.log("Advertisers: ", apiData);
  //             setAllAdvertisers(apiData)
  //             setPending(false)

  //         },
  //         errorRes => {
  //             console.log("campaign creation error: ", errorRes);

  //         }
  //     )
  // }

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      // console.log("user status: ", user)
      setCurrentUser(user);
      // getAdvertisers()
      setPending(false);
    });
  }, []);

  if (pending) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        allAdvertisers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import * as firebase from 'firebase/app'
import "firebase/auth";
import "firebase/storage";


const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
})
// Your web app's Firebase configuration
// console.log("env variables: ", process.env)
// var firebaseConfig = {
//     apiKey: "AIzaSyA8KpklXMcO8XVESPsf0pV5gubM0ju1SVg",
//     authDomain: "eng-hue-272723.firebaseapp.com",
//     databaseURL: "https://eng-hue-272723.firebaseio.com",
//     projectId: "eng-hue-272723",
//     storageBucket: "eng-hue-272723.appspot.com",
//     messagingSenderId: "871035076740",
//     appId: "1:871035076740:web:e273e2cf5381b6134b02d1"
// };
// // Initialize Firebase
// const app = firebase.initializeApp(firebaseConfig);
export const storage = firebase.storage()
export default app
import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
// import contextReducer from './reducer/contextReducer';
// import questionReducer from './reducer/questionReducer';
import adsReducer from './reducer/adsReducer';
import advertiserReducer from './reducer/advertiserReducer';


const combinedReducers = combineReducers({
    // contextReducer, 
    // questionReducer,
    // userDetailsReducer
    adsReducer,
    advertiserReducer
})

export default createStore(combinedReducers ,applyMiddleware(thunk))
import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "../App.scss";
import { frontendRoutes } from "../utils/constants";
import Login from "../views/Pages/Login/Login";
import Register from "../views/Pages/Register/Register";
import ForgotPassword from "../views/Pages/ForgotPassword/ForgotPassword";
import PrivateRoute from "./PrivateRoute";
import { AuthProvider } from "./Auth";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const DefaultLayout = React.lazy(() => import("../containers/DefaultLayout"));

export default function RoutingComponent() {
  // Setup the `beforeunload` event listener
  const setupBeforeUnloadListener = () => {
    console.log("setupBeforeUnloadListener called");
    window.addEventListener("beforeunload", (ev) => {
      console.log("event listener called");
      ev.preventDefault();
      return "bla";
    });
  };

  useEffect(() => {
    setupBeforeUnloadListener();
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path={frontendRoutes.login.path}
              name={frontendRoutes.login.name}
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path={frontendRoutes.register.path}
              name={frontendRoutes.register.name}
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path={frontendRoutes.forgotPassword.path}
              name={frontendRoutes.forgotPassword.name}
              render={(props) => <ForgotPassword {...props} />}
            />
            <PrivateRoute
              path={frontendRoutes.home.path}
              name={frontendRoutes.home.name}
              component={DefaultLayout}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
}

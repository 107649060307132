import React, { Component } from 'react';
import './App.scss';
import RoutingComponent from './customComponents/RoutingComponent';
import { Provider } from 'react-redux';
import store from './store';


class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <RoutingComponent />
      </Provider>
    );
  }
}

export default App;

import React from 'react'
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    CardHeader,
    Card,
    CardBody,
    Alert,
    Form,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    FormFeedback
} from "reactstrap";
import { useInput, useSubmit } from '../../../hooks/form';
import { handleValidation, validations } from '../../../utils/validations';

export default function RequestModal(props) {

    const requestEmail = useInput('requestEmail', '', handleValidation, validations.EMAIL)
    const submit = useSubmit([requestEmail], handleSuccess);
    function handleSuccess(formData) {
        console.log("Data Length==> ", formData);
        sendEmailRequest(formData)
        // name.clearInput();
        // vastUrl.clearInput();
        // height.clearInput();
        // width.clearInput();
    }

    function sendEmailRequest(formData) {
        console.log("Requested email: ", formData.requestEmail)
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>Join SkipQuest!</ModalHeader>
            <ModalBody>
                <Card className="mx-4">
                    <CardHeader>Enter an email to participate in beta, we will respond soon.</CardHeader>
                    <CardBody className="p-4">
                        <Form noValidate {...submit.props}>
                            <InputGroup className="mb-3">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>@</InputGroupText>
                                </InputGroupAddon>
                                <Input type="text" placeholder="Email" autoComplete="email" {...requestEmail.props} />
                                <FormFeedback>{requestEmail.helperText}</FormFeedback>

                            </InputGroup>
                            <Button color="primary">Submit</Button>
                        </Form>
                    </CardBody>
                </Card>
            </ModalBody>
            {/* <ModalFooter>
				<Button color="secondary" onClick={props.toggle}>
					Cancel
        </Button>
			</ModalFooter> */}
        </Modal>
    )
}

import React, { useCallback, useContext } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Alert, FormFeedback, CardGroup } from 'reactstrap';
import { useHistory, Redirect, Link } from 'react-router-dom';
import app from '../../../fire_base';
import { AuthContext } from '../../../customComponents/Auth';
import { frontendRoutes } from '../../../utils/constants';
import { useInput, useSubmit } from '../../../hooks/form';
import { handleValidation, validations } from '../../../utils/validations';
import useAlert from '../../../hooks/useAlert';

export default function Register() {

  const history = useHistory()

  const email = useInput('email', '', handleValidation, validations.EMAIL)
  const password = useInput('password', '', handleValidation, validations.EMPTY)

  const appAlert = useAlert(false);

  function handleAlertVisibility() {
    appAlert.changeVisibility(false);
  }
  const submit = useSubmit([email, password], handleSuccess);

  function handleSuccess(formData) {
    console.log("Data Length==> ", formData);
    signUp(formData)
    // name.clearInput();
    // vastUrl.clearInput();
    // height.clearInput();
    // width.clearInput();
  }

  const signUp = useCallback(async (formData) => {


    console.log('email: %s, password: %s', formData.email, formData.password)
    try {
      await app.auth().createUserWithEmailAndPassword(formData.email, formData.password)
      history.push("/");
    }
    catch (error) {
      // alert(error)
      console.log("error from firebase: ", error)
      appAlert.changeVisibility(true);
      appAlert.handleMessage(error.message, true);
      setTimeout(() => {
        appAlert.changeVisibility(false);
      }, 5000);
    }
  })

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/" />;
  }

  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="9" lg="9" xl="9">
            <CardGroup>

              <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '50%' }}>
                <CardBody className="text-center">
                  <div className='d-flex flex-column align-items-center'>
                    {/* <h2>Sign up</h2> */}
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua.</p> */}
                    <img src={require('../../../assets/img/brand/SkipQuestLogoLight.png')} height='200px' width='200px' />
                    <Link to={frontendRoutes.login.path}>
                      <Button color="primary" className="mt-3" active tabIndex={-1}>Login Now!</Button>
                    </Link>
                  </div>
                </CardBody>
              </Card>

              <Card className="p-4">
                <CardBody className="p-4">
                  <Alert
                    color={appAlert.isErrMsg ? "danger" : 'success'}
                    isOpen={appAlert.isVisible}
                    toggle={handleAlertVisibility}
                  >
                    {appAlert.message}
                  </Alert>
                  <Form noValidate {...submit.props}>
                    <h1>Register</h1>
                    <p className="text-muted">Create your account</p>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>@</InputGroupText>
                      </InputGroupAddon>
                      <Input type="text" placeholder="Email" autoComplete="email" {...email.props} />
                      <FormFeedback>{email.helperText}</FormFeedback>
                    </InputGroup>
                    <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="icon-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password" placeholder="Password" autoComplete="new-password" {...password.props} />
                      <FormFeedback>{password.helperText}</FormFeedback>
                    </InputGroup>
                    <Button color="primary" block>Create Account</Button>
                    {/* <Link to='/login'>Login</Link> */}
                  </Form>
                </CardBody>
                {/* <CardFooter className="p-4">
                <Row>
                  <Col xs="12" sm="6">
                    <Button className="btn-facebook mb-1" block><span>facebook</span></Button>
                  </Col>
                  <Col xs="12" sm="6">
                    <Button className="btn-twitter mb-1" block><span>twitter</span></Button>
                  </Col>
                </Row>
              </CardFooter> */}
              </Card>
            </CardGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

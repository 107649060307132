export const backendRoutes = {
  BASE_URL: "https://core.skipquest.com/",
  // AD_URL: "https://rt.skipquest.com/demo?ad=",
  AD_URL: "https://rt.skipquest.com/players?ad=",
  VPAID_URL: "https://rt.skipquest.com/vpaid?ad=",
  LOGIN: "auth/signin",
  USER_REGISTRATION: "users/registration",
  FORGOT_PASSWORD: "auth/forgot-password",
  UPLOAD_LOGO: "logos",
  UPLOAD_CLIP: "clips",
  USERS: "users/",
  CAMPAIGN: "campaign/",
  ADS: "creative/",
  CAMPAIGN_ADS: "campaign/creative/",
  INSERT_QUESTION: "creative/question/insert",
  UPDATE_QUESTION: "creative/question/update",
  DELETE_QUESTION: "creative/question/delete",
  DELETE_ANSWER: "creative/question/answer/delete",
  GET_ADVERTISER: "advertiser/get",
  GET_GEO_CODES: "geo/get",
  GET_AD_REPORT: "creative/report/get",
  GET_DAILY_AD_REPORT: "creative/report/daily/get",
  GET_HOURLY_AD_REPORT: "creative/report/hourly/get",
};

export const frontendRoutes = {
  login: { path: "/login", name: "Login page" },
  register: { path: "/register", name: "Register page" },
  forgotPassword: { path: "/forgot-password", name: "Forgot password page" },
  home: { path: "/", name: "Home" },
  dashboard: { path: "/dashboard", name: "Dashboard" },
  campaigns: { path: "/campaigns", name: "Campaigns" },
  createCampaigns: { path: "/campaigns/create", name: "Create Campaign" },
  editCampaigns: { path: "/campaigns/edit", name: "Edit Campaign" },
  ads: { path: "/ads", name: "Ads" },
  createAds: { path: "/ads/create", name: "Create Ads" },
  editAds: { path: "/ads/edit", name: "Edit Ads" },
  adQuestions: { path: "/ads/questions", name: "Questions" },
  createAdQuestions: {
    path: "/ads/questions/create-question",
    name: "Create Questions",
  },
  editAdQuestions: {
    path: "/ads/questions/edit-question",
    name: "Edit Questions",
  },
  selectAdvertiser: { path: "/advertisers", name: "Select Advertiser" },
  previewAd: { path: "/ads/preview", name: "Preview Ad" },
  // users: { path: "/users", name: "Users" },
  // createUser: { path: "/users/create", name: "Create" },
  // user: { path: "/users/:id", name: "User Details" }
};

export const messages = {
  EMAIL_SENT:
    "An email is sent to reset your password.\n Please login to continue.",
  NETWORK_FAILURE: " Server not found, Please check your network.",
  AD_CREATED: "Ad created Successfully.",
  AD_UPDATED: "Ad updated Successfully.",
  QUESTION_CREATED: "Question created Successfully.",
  QUESTION_UPDATED: "Question updated Successfully.",
  CAMPAIGN_CREATED: "Campaign created Successfully.",
  CAMPAIGN_UPDATED: "Campaign updated Successfully.",
  SELECT_ADVERTISER: "Please select an advertiser.",
};

export const quesDelayMax = 10;

export const defaultShowHintAfter = 2;

export const dashboardTimeFilter = [
  { label: "Daily", value: "daily" },
  { label: "Hourly", value: "hourly" },
];

export const pacingTypes = [
  {
    label: "ASAP",
    value: "ASAP",
  },
  {
    label: "EVEN",
    value: "EVEN",
  },
];

export const frequencyCapTypes = [
  {
    label: "day",
    value: "day",
  },
  {
    label: "week",
    value: "week",
  },
  {
    label: "month",
    value: "month",
  },
  {
    label: "Campaign lifetime",
    value: "campaign",
  },
];

export const bannerLayoutTypes = [
  {
    label: "Bottom",
    value: "bottom",
  },
  {
    label: "Top",
    value: "top",
  },

  {
    label: "Left",
    value: "left",
  },

  {
    label: "Right",
    value: "right",
  },
];

export const contextQuestionTypes = {
  MCQ4: "Correct-Incorrect with 4 answers.",
  MCQ3: "Correct-Incorrect with 3 answers",
  TRUEANDFALSE: "2 answers (TRUE/FALSE)",
  SQ4: "Survey Question with 4 answers",
  SQ3: "Survey Question with 3 answers",
};

export const contextStatus = {
  ACTIVE: "ACTIVE",
  PAUSE: "PAUSE",
};

export const contextReviewStatus = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
};

export const userRole = {
  ADMIN: 1,
  EDITOR: 2,
  CLIENT: 3,
  PUBLISHER: 4,
};

export const userTypes = {
  ADMIN: "Admin",
  CLIENT: "Client",
  EDITOR: "Editor",
  PUBLISHER: "Publisher",
};

export const videoExtensions = [
  ".flv",
  ".mp4",
  ".m3u8",
  ".ts",
  ".3gp",
  ".mov",
  ".avi",
  ".wmv",
];

export const shareModalType = {
  vpaidAd: "VPAID",
  videoAd: "VIDEO",
};
